<template>
  <page-view>
    <template v-slot:nav>
      <nav-bar title="收款方式设置" />
    </template>
    <div>
      <!-- 内容 -->
      <van-notice-bar background="#fef5d4" color="#865d19" left-icon="volume-o" mode="closeable">
        您未开通微信、支付宝扫码收款, 点击 <a style="color:#1989fa" @click="goToOpenEpayment" href="javascript:void(0)">去开通</a>
      </van-notice-bar>
      <van-cell title="启用微信、支付宝" :value="modeName" @click="goToPattern" is-link />
      <div class="cell-title text-color somber">常用支付方式</div>
      <!-- 支付方式列表 -->
      <van-cell-group>
        <van-cell :key="configItem.payType" v-for="(configItem) in baseEpayment">
          <div class="cell-left" slot="title">
            <van-image lazy-load width="28px" :src="payIcons(configItem)"></van-image>
            <span class="pay__name">{{ configItem.typeName }}</span>
          </div>
          <template #right-icon>
            <van-switch size="22px" @input="val => switchChange(val, configItem)" active-color="#ff6a3c" :value="configItem.status" />
          </template>
        </van-cell>
      </van-cell-group>
      <div class="cell-title text-color somber">其它支付方式</div>
      <!-- 其它支持方式列表 -->
      <van-cell-group>
        <van-cell :key="index" v-for="(configItem, index) in otherEpayment">
          <div class="cell-left" slot="title">
            <van-image lazy-load width="28px" :src="payIcons(configItem)"></van-image>
            <span class="pay__name">{{ configItem.typeName }}</span>
          </div>
          <template #right-icon>
            <van-switch size="22px" @input="val => switchChange(val, configItem)" active-color="#ff6a3c" :value="configItem.status" />
          </template>
        </van-cell>
      </van-cell-group>
      <van-cell-group v-if="expandMore">
        <van-cell :key="index" v-for="(configItem, index) in moreEpayment">
          <div class="cell-left" slot="title">
            <van-image lazy-load width="28px" :src="payIcons(configItem)"></van-image>
            <span class="pay__name">{{ configItem.typeName }}</span>
          </div>
          <template #right-icon>
            <van-switch size="22px" @input="val => switchChange(val, configItem)" active-color="#ff6a3c" :value="configItem.status" />
          </template>
        </van-cell>
      </van-cell-group>
      <div class="look-more text-color primary" @click="lookMore">
        {{ moreText }} <i class="van-icon van-icon-play" style="font-size: 10px;" :class="[expandMore?'up':'down']"></i></div>
    </div>
    <template v-slot:routerView>
      <router-view />
    </template>
  </page-view>
</template>
<style lang="scss">
  @import './index.scss';
</style>
<script>
import PageView from '@/layouts/PageView'
import NavBar from '@/components/nav-bar'
import PresetsIcon from '@/model/pay/pay-icon'
import ItemModel from '@/model/setting/epayment-gather'
import {
  NoticeBar,
  Switch,
  Cell,
  CellGroup,
  Image,
  Lazyload,
  Dialog
} from 'vant'
import { mapGetters } from 'vuex'
import Vue from 'vue'
Vue.use(Lazyload)

export default {
  name: 'SaleEpayMentGather',
  data () {
    const itemModel = new ItemModel()
    return {
      itemModel,
      pConfig: [],
      epaymentData: [],
      expandMore: false,
      modeEnum: {
        0: '记账模式',
        1: '收款模式',
        2: '双重模式'
      },
      modeName: '',
      selectedMaxSize: 10,
      selectedMinSize: 1,
      closeAlertInfo: false
    }
  },
  components: {
    PageView,
    NavBar,
    [NoticeBar.name]: NoticeBar,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Switch.name]: Switch,
    [Image.name]: Image,
    [Dialog.name]: Dialog
  },
  created () {
    this.$bus.$on('goToGathering', res => {
      this.modeName = this.modeEnum[res]
    })
  },
  mounted () {
    this.getDataList()
    this.initMode()
  },
  beforeDestroy () {
  },
  methods: {
    payIcons (configItem) {
      if (configItem.linkedPayType) return PresetsIcon[configItem.linkedPayType]
      return PresetsIcon[configItem.payType]
    },
    getDataList () {
      this.itemModel.getDataList().then(res => {
        this.epaymentData = res
      })
    },
    lookMore () {
      this.expandMore = !this.expandMore
    },
    goToPattern () {
      this.$router.push('/setting/sales/epayment/pattern')
    },
    switchChange (val, item) {
      if (val && this.checkedData.length === this.selectedMaxSize) {
        Dialog({
          message: '最多只能选十种噢！'
        })
        return
      }
      if (!val && this.checkedData.length === this.selectedMinSize) {
        Dialog({
          message: '至少选择一种噢！'
        })
        return
      }
      if (val && item.payType === 201 && this.paymentNotOpen) {
        Dialog({
          title: '操作提示',
          message: '您未开通微信、支付宝扫码收款',
          confirmButtonText: '去开通',
          confirmButtonColor: 'ff6a3c'
        }).then(() => {
          this.goToOpenEpayment()
        }).catch(() => {
          // on cancel
        })
        return
      }
      item.status = val
      const func = val ? this.itemModel.submitEpayment : this.itemModel.closeEpayment
      this.changeStatus(item, func)
      // 唤醒声音
      if (!window.$bridge) return
      window.$bridge.message.awakenVoice()
    },
    changeStatus (item, func) {
      func(item)
      if (item.linkedPayType) func({ payType: item.linkedPayType })
    },
    initMode () {
      this.itemModel.getPattern().then(res => {
        this.modeName = this.modeEnum[res]
      })
    },
    closeAlert () {
      this.closeAlertInfo = false
    },
    goToOpenEpayment () {
      this.$router.replace('/acquire-intro/progress')
    }
  },
  computed: {
    ...mapGetters({
      thirdPaymentOpen: 'setting/getThirdPaymentOpen'
    }),
    paymentNotOpen () {
      return !this.thirdPaymentOpen
    },
    baseEpayment () {
      return this.epaymentData.slice(0, 8)
    },
    otherEpayment () {
      return this.epaymentData.slice(8, 8)
    },
    moreEpayment () {
      return this.epaymentData.slice(8)
    },
    checkedData () {
      return this.epaymentData.filter(item => {
        return item.status === true
      })
    },
    moreText () {
      return this.expandMore ? '收起' : '查看更多'
    }
  },
  watch: {}
}
</script>
