export default {
  1: require('@/assets/images/setting/sales/cash.png'), // 现金
  2: require('@/assets/images/setting/sales/bank.png'), // 银行卡
  3: require('@/assets/images/setting/sales/vip.png'), // 储值卡
  4: require('@/assets/images/setting/sales/times.png'), // 计次卡
  5: require('@/assets/images/setting/sales/debt.png'), // 欠款
  6: require('@/assets/images/setting/sales/alipay-gather.png'), // 支付宝记账
  8: require('@/assets/images/setting/sales/wx-gather.png'), // 微信记账
  9: require('@/assets/images/setting/sales/jd.png'), // 京东钱包
  10: require('@/assets/images/setting/sales/baidu.png'), // 百度钱包
  11: require('@/assets/images/setting/sales/yibao.png'), // 医保卡
  12: require('@/assets/images/setting/sales/tuangou.png'), // 团购,
  13: require('@/assets/images/setting/sales/meituan.png'), // 美团
  14: require('@/assets/images/setting/sales/eleme.png'), // 饿了么
  15: require('@/assets/images/setting/sales/daijinquan.png'), // 代金券,
  16: require('@/assets/images/setting/sales/dianping.png'), // 大众点评
  101: require('@/assets/images/setting/sales/alipay.png'), // 支付宝收款
  102: require('@/assets/images/setting/sales/weixin.png'), // 微信收款
  201: require('@/assets/images/setting/sales/fixed-code.png'),
  1204: require('@/assets/images/account-statement/bestpay.png'), // 翼支付收款
  204: require('@/assets/images/account-statement/bestpay-gather.png'), // 翼支付记账
  304: require('@/assets/images/account-statement/cloudflashpay-acquire.png'), // 云闪付记账
  1304: require('@/assets/images/account-statement/cloudflashpay-acquire.png')
}
